<template>
	<div class="collaboration-area">
		<div class="collaboration-area-form">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
				<el-form-item :label="$t('claimwork')" prop="claimValue">
					<el-cascader :options="beatMuiscList" :props="{ multiple: false }" :placeholder="$t('claimedwork')"
						popper-class="reset-popper-bg-color" @change="changeClaimCascader" v-model="claimValue"
						clearable></el-cascader>
					<!-- <el-select @change="cschange" v-model="beatPid"
		  	placeholder="请选择你已认领的作品">
		  	<el-option v-for="item in beatMuiscList" :key="item.value" :label="item.beatPid"
		  		:value="item.value">
		  	</el-option>
		  </el-select> -->
				</el-form-item>
				<el-form-item :label="$t('songName')" prop="title">
					<el-input v-model="ruleForm.title" :placeholder="$t('songname')"></el-input>
				</el-form-item>
				<el-form-item :label="$t('producer')" prop="creator">
					<el-input v-model="creator" disabled></el-input>
				</el-form-item>
				<el-form-item :label="$t('audiofile')" prop="name">
					<el-upload class="upload-demo" :action="uploadMusicFileUrl" :before-upload="testformatAudio"
						:on-success="callbackAudio" ref="Audio" :on-preview="handlePreviewAudio"
						:on-remove="handleRemoveAudio" :limit="1" :on-exceed="handleExceedAudio"
						:file-list="fileListAudio">
						<el-button size="small" plain>
							<div class="btn-wrap">
								<Icon name="icon-7key-musicshangchuan" theme-color="iconColorAll" width="16"
									height="16"></Icon>
								<div class="btn-wrap-text">{{ $t("clickUpload") }}</div>
							</div>
						</el-button>
					</el-upload>
					<!-- <div class="tips">支持mp3，大小不超过100M</div> -->
				</el-form-item>
				<el-form-item :label="$t('uploadlyrics')" prop="name">
					<el-upload class="upload-demo" :action="uploadUrl" :before-upload="testformatLyrics"
						:on-success="callbackLyrics" ref="Lyrics" :on-preview="handlePreviewLyrics"
						:on-remove="handleRemoveLyrics" :limit="1" :on-exceed="handleExceedLyrics"
						:file-list="fileListLyrics">
						<el-button size="small" plain>
							<div class="btn-wrap">
								<Icon name="icon-7key-musicshangchuan" theme-color="iconColorAll" width="16"
									height="16"></Icon>
								<div class="btn-wrap-text">{{ $t("clickUpload") }}</div>
							</div>
						</el-button>
					</el-upload>
					<!-- <div class="tips">支持docx、lrc文件格式，大小不超过100M</div> -->
				</el-form-item>
				<el-form-item class="team-type" :label="$t('collaboratenot')" prop="name">
					<!-- <el-checkbox-group v-model="teamType" :max="1">
						<el-checkbox border v-for="item in team_type" :label="item.id" :key="item.id">
							{{ item.name }}
							<span>
							</span>
						</el-checkbox>
					</el-checkbox-group> -->
					<el-radio-group v-model="teamType">
						<el-radio v-for="item in team_type" :label="item.id" :key="item.id">{{item.name}}</el-radio>
						<!-- <el-radio :label="1">{{$t('woman')}}</el-radio> -->
					</el-radio-group>
				</el-form-item>
				<el-form-item label="" prop="name">
					<el-checkbox v-model="checked">{{ $t("readAndAgree") }}
					</el-checkbox>
					<span @click="showAgreement(4)" style="color: #3875FB; cursor: pointer;">《{{
						$t('musicianOccupancyAgreement')
					}}》</span>
				</el-form-item>
				<div class="submit">
					<el-button v-show="!editId" @click="toissue" type="primary">{{ $t("publishWork") }}</el-button>
					<el-button v-show="editId" @click="upedit" type="primary">更改</el-button>
					<!-- 上架须知弹窗 -->
					<PutOnPopup ref="putOnPopup" @confirm="addMusic"></PutOnPopup>
					<!-- 上传成功弹窗 -->
					<PutOnSucceedPopup @confirm="pushWork" ref="putOnSucceedPopup"></PutOnSucceedPopup>
				</div>
			</el-form>
		</div>
		<!-- 协议弹窗 -->
		<AgreementPopup ref="popupAgreement"></AgreementPopup>
	</div>
</template>

<script>
	import {
		beatMuiscList,
		showMusic,
		editMusic,
		editBeatMusic
	} from "@/api/Mine"
	import {
		addBeatMusic
	} from "@/api/Upfile"
	import PutOnPopup from "@/components/popup/PutOnPopup"
	import PutOnSucceedPopup from "@/components/popup/PutOnSucceedPopup"
	import AgreementPopup from "@/components/popup/AgreementPopup.vue"
	export default {
		props: {},
		data() {
			return {

				beatMuiscList: [],
				musicId: this.$route.query.musicId || '',
				editId: this.$route.query.editId || '',
				creator: '',
				uploadMusicFileUrl: process.env.VUE_APP_UPLOAD_MUSIC_URL, //上传AR
				uploadUrl: process.env.VUE_APP_UPLOAD_LYRIC_URL,
				// uploadUrl: 'http://ht.7keymusic.com/api/uploadLyric',
				fileListAudio: [],
				fileListLyrics: [],
				dialogVisible: false,
				claimValue: "",
				teamType: [],
				shangjiaxuzhi: this.$t("Collaboration.shangjiaxuzhi"),
				// team_type: [{
				// 		id: '1',
				// 		name: this.$t("need_to_fill_in_words"),
				// 		isDisabled: false
				// 	},
				// 	{
				// 		id: '2',
				// 		name: this.$t("need_a_guide"),
				// 		isDisabled: false
				// 	}
				// ],
				team_type: [],
				// team_type: [{
				// 		id: '1',
				// 		name: `${this.$t('collaboratenot')}`,
				// 		isDisabled: false
				// 	},
				// 	{
				// 		id: '2',
				// 		name: `${this.$t('guidesing')}`,
				// 		isDisabled: false
				// 	}
				// ],
				ruleForm: {
					urlAudio: '',
					urlLyrics: '',
					title: '',
				},
				rules: {
					title: [{
							message: this.$t("songname"),
							trigger: "blur"
						},
						// { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
					],
					creator: [{
						message: this.$t("creatornotnull"),
						trigger: "blur"
					}],
					claimValue: [{
						message: this.$t("selectclaimwork"),
						trigger: "change"
					}],
					urlAudio: [{
						message: this.$t("audionotnull"),
						trigger: "change"
				}	],
					urlLyrics: [{
						required: true,
						message: this.$t("lyricsnotnull"),
						trigger: "change"
					}],
				},
				fileList: [],
				imageUrl: "",
				// options: [
				//   {
				//     value: 1,
				//     label: "男歌手",
				//   },
				//   {
				//     value: 2,
				//     label: "女歌手",
				//   },
				//   {
				//     value: 3,
				//     label: "男女歌手",
				//   },
				// ],
				checked: false,
				beatPid: '', //0:beat 1:作曲
				recommend: '',//4: beat 5: 作曲
				agreeType: 4,
			};
		},
		components: {
			PutOnPopup,
			PutOnSucceedPopup,
			AgreementPopup
		},
		computed: {},
		created() {},
		mounted() {
			// console.log('2222222222222222222222222')
			console.log(process.env.VUE_APP_UPLOAD_LYRIC_URL, 'process.env.VUE_APP_UPLOAD_LYRIC_URL')
			if (this.editId) {
				console.log('editId');
				let params = {}
				params.music_id = this.editId
				showMusic(params).then(res => {
					let data = res.data.list
					this.ruleForm.title = data.title
					this.beatPid = data.beatPid; //0:beat 1:作曲
					if (this.beatPid == 0) {
						let arr = [{
								id: '1',
								name: `${this.$t('collaboratenot')}`,
								isDisabled: false
							},
							{
								id: '2',
								name: `${this.$t('guidesing')}`,
								isDisabled: false
							}
						]
						this.team_type = arr;
					}else if(this.beatPid == 1) {
						let arr = [{
								id: '2',
								name: `${this.$t('guidesing')}`,
								isDisabled: false
							}
						]
						this.team_type = arr;
					}
					// console.log(data.url)
					this.ARfileList.push({
						['url']: data.url,
						['name']: data.title
					})
					this.MultifileList.push({
						['url']: data.lyric,
						['name']: data.title
					})
					// this.ARfileList.push({['name']:data.title})
					// console.log(fileList);
				})
			}
			if (this.musicId) {
				console.log('musicId');
				let params = {}
				params.music_id = this.musicId
				showMusic(params).then(res => {
					let data = res.data.list
					this.ruleForm.title = data.title
					this.beatPid = data.beatPid; //0:beat 1:作曲
					if (this.beatPid == 0) {
						let arr = [{
								id: '1',
								name: `${this.$t('collaboratenot')}`,
								isDisabled: false
							},
							{
								id: '2',
								name: `${this.$t('guidesing')}`,
								isDisabled: false
							}
						]
						this.team_type = arr;
						this.claimValue = res.data.list.musicId
					}else if(this.beatPid == 1) {
						let arr = [{
								id: '2',
								name: `${this.$t('guidesing')}`,
								isDisabled: false
							}
						]
						this.team_type = arr;
					}
					// this.musicData = res.data.list;
					// let data = res.data.list
					// this.ruleForm.title = data.title
					// this.fileListAudio.push({['url']:data.url,['name']:data.title})
					// this.fileListLyrics.push({['url']:data.lyric,['name']:data.title})
					// console.log(this.fileListAudio,this.fileListLyrics,'this.fileListLyrics')
				})
			}
			beatMuiscList().then(res => {
				for (let key in res.data.list) {
					let obj = {}
					obj.label = res.data.list[key].title
					obj.value = res.data.list[key].musicId
					// obj.value = res.data.list[key].musicId
					// obj.beatPid = res.data.list[key].title
					obj.isLyrics = res.data.list[key].beatPid
					this.beatMuiscList.push(obj)
					if (this.musicId == res.data.list[key].musicId) {
						this.isLyrics = this.beatMuiscList[key].isLyrics;
					}
					if (this.isLyrics != 0) {
						this.team_type[0].isDisabled = true
					} else {
						this.team_type[0].isDisabled = false
					}
				}
				// console.log(parseInt(this.musicId)||'','beatPid')
				// this.beatPid = parseInt(this.musicId)||'';
				this.claimValue = this.musicId.split(" ")
				console.log(this.claimValue, 'this.claimValue')
			})

			this.creator = this.$store.state.loginname
			let music_id = this.$route.query.id
			if (music_id) {
				let params = {}
				params.music_id = music_id
				showMusic(params).then(res => {
					let data = res.data.list
					console.log(data);
					this.ruleForm.title = data.title
					this.ARfileList.push(data.url)
					this.MultifileList.push(data.lyric)
				})
			} else {

			}
		},
		watch: {},
		methods: {
			showAgreement(type) {
			  this.agreeType = type
			  this.$refs.popupAgreement.open()
			  console.log('协议',this.agreeType,'2222',type,'1111')
			  this.$refs.popupAgreement.getAgreement(this.agreeType)
			},
			// 上传Audio成功
			callbackAudio(res) {
				this.ruleForm.urlAudio = this.uploadMusicFileUrl + '/' + res.data.url;
				console.log(this.ruleForm.urlAudio, 'this.ruleForm.urlAudio')
			},
			// Audio格式校验
			testformatAudio(file) {
				console.log(file.type, 'file.type')
				// const ar_isWAV = file.type === "audio/wav";
				const ar_isMP3 = file.type === "audio/mpeg";
				const ar_isLt100M = file.size / 1024 / 1024 < 100;
				// if( ar_isWAV || ar_isMP3){
				if (ar_isMP3) {
					if (!ar_isLt100M) {
						this.$message({
							type: "error",
							message: this.$t("Collaboration.toobig"),
						});
						return false;
					} else {}
				} else {
					this.$message({
						type: "error",
						message: this.$t("Collaboration.rightAudio"),
					});
					return false;
				}
			},
			// 移出Audio
			handleRemoveAudio() {
				this.$refs.Audio.clearFiles();
			},
			// Audio预览
			handlePreviewAudio(file) {
				console.log(file);
			},
			// Audio移出前
			beforeRemoveAudio(file, fileList) {
				return this.$confirm(`${$t("confirmDel")} ${file.name}？`);
			},
			// Audio 
			handleExceedAudio(files, fileList) {
				this.$message.warning(
					`${$t("limitselection")} ${files.length} ${$t("Selected")} ${files.length + fileListAudio.length} ${$t("filenum")}`
					);
			},


			// 上传Lyrics成功
			callbackLyrics(res) {
				this.ruleForm.urlLyrics = this.uploadMusicFileUrl + '/' + res.data.url;
				console.log(this.ruleForm.urlLyrics, 'this.ruleForm.urlLyrics')
			},
			// Lyrics格式校验
			testformatLyrics(file) {
				console.log(file, 'file')
				// const ar_isWAV = file.type === "audio/wav";
				// const ar_isMP3 = file.type === "audio/mpeg";
				const ar_isDoc = file.type === "application/msword"
				const ar_isDocx = file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
				const ar_isLrc = file.type === ""
				const ar_isLt100M = file.size / 1024 / 1024 < 100;
				// if( ar_isWAV || ar_isMP3){
				// if (ar_isDocx) {
				// 	if (!ar_isLt100M) {
				// 		this.$message({
				// 			type: "error",
				// 			message: this.$t("Collaboration.toobig"),
				// 		});
				// 		return false;
				// 	} else {}
				// } else {
				// 	this.$message({
				// 		type: "error",
				// 		message: this.$t("Collaboration.rightLyrics"),
				// 	});
				// 	return false;
				// }
				if (!ar_isLt100M) {
					this.$message({
						type: "error",
						message: this.$t("Collaboration.toobig"),
					});
					return false;
				} else {}
			},
			// 移出Lyrics
			handleRemoveLyrics() {
				this.$refs.Lyrics.clearFiles();
			},
			// Lyrics预览
			handlePreviewLyrics(file) {
				console.log(file);
			},
			// Lyrics移出前
			beforeRemoveLyrics(file, fileList) {
				return this.$confirm(`${$t("confirmDel")} ${file.name}？`);
			},
			// Lyrics
			handleExceedLyrics(files, fileList) {
				this.$message.warning(
					`${$t("limitselection")} ${files.length} ${$t("Selected")} ${files.length + fileListLyrics.length} ${$t("filenum")}`
					);
			},
			changeClaimCascader(val) {
				// if (val.length > 1) {
				// 	this.claimValue = val.pop();
				// }
				let params = {}
				params.music_id = this.claimValue.join("")
				showMusic(params).then(res => {
					let data = res.data.list
					console.log(data);
					this.ruleForm.title = data.title
					// this.ARfileList.push(data.url)
					// this.MultifileList.push(data.lyric)
				})
			},
			issue() {
				// this.dialogVisible = true
				console.log('111111111111')
				this.$refs.putOnPopup.open()
			},
			// 编辑
			upedit() {
				let params = {
					//  music_id:this.editId,
					//   title:this.samplename,
					//   beatPid:this.beatPid,
					// //   recommend:5,
					//   url:this.ar,
					//   lyric:this.multi,
					//   teamType:this.teamType.join(',')

					music_id: this.editId,
					title: this.ruleForm.title,
					beatPid: this.claimValue.join(""),
					// recommend: 5,
					url: this.ruleForm.urlAudio,
					lyric: this.ruleForm.urlLyrics,
					teamType: this.teamType
				}
				console.log(params);
				// return false;
				editBeatMusic(params).then(res => {
					// console.log(res);return;
					if (res.status == 1) {
						this.$message({
							type: 'success',
							message: res.msg
						})
						this.$router.push("/personalCenter/myClaim/complete")
					} else {
						this.$message({
							type: "error",
							message: res.msg
						})
					}
				})
			},
			pushWork(){
				this.$router.push("/personalCenter/myWork/workarea");
			},
			// 发布
			toissue() {
				console.log(this.claimValue.join(""),this.teamType,'this.teamType');
				// let recommend;
				// let params = {
				// 	title: this.ruleForm.title,
				// 	beatPid: this.claimValue.join(""),
				// 	recommend: recommend,//4: beat 5: 作曲
				// 	url: this.ruleForm.urlAudio,
				// 	lyric: this.ruleForm.urlLyrics,
				// 	teamType: this.teamType
				// }
				if(this.beatPid == 0){
					this.recommend = 4
					if(this.ruleForm.urlAudio == ''){
						this.$message({
							type: "error",
							message: this.$t("audionotnull")
						})
						return false;
					}
					if(this.checked == false){
						this.$message({
							type: "error",
							message: this.$t("readmusicianagreement")
						})
						return false;
					}
					this.$refs.putOnPopup.open();
					// this.addMusic(params);
				}else if(this.beatPid == 1){
					this.recommend = 5
					if(this.ruleForm.urlAudio == ''){
						this.$message({
							type: "error",
							message: this.$t("audionotnull")
						})
						return false;
					}
					if(this.ruleForm.urlLyrics == ''){
						this.$message({
							type: "error",
							message: this.$t("lyricsnotnull")
						})
						return false;
					}
					if(this.checked == false){
						this.$message({
							type: "error",
							message: '请阅读并同意《音乐人入驻协议》'
						})
						return false;
					}
					this.$refs.putOnPopup.open();
					// this.addMusic(params);
				}
				
				
				// this.dialogVisible = false
				
			},
			addMusic(){
				this.$refs.putOnPopup.cancel();
				let params = {
					title: this.ruleForm.title,
					beatPid: this.claimValue.join(""),
					// beatPid: 0,
					recommend: this.recommend,//4: beat 5: 作曲
					url: this.ruleForm.urlAudio,
					lyric: this.ruleForm.urlLyrics,
					teamType: this.teamType
				}
				addBeatMusic(params).then(res => {
					if (res.status == 1) {
						this.$message({
							type: 'success',
							message: res.msg
						})
						this.$refs.putOnSucceedPopup.open()
						// setTimeout(() => {
						// 	this.$router.push("/personalCenter/myWork/workarea");
						// }, 1500);
						// this.$router.push("/personalCenter/myWork/workarea");
						// this.successdialog = true
						// this.dialogVisible = true
				
					} else {
						for (let item in res.data) {
							setTimeout(() => {
								this.$notify({
									title: this.$t("demoupnotifytitle"),
									type: 'error',
									message: res.data[item]
				
								})
							}, 1500);
				
						}
					}
				})
			},
			// submitForm(formName) {
			//   this.$refs[formName].validate((valid) => {
			//     if (valid) {
			//       alert("submit!");
			//     } else {
			//       console.log("error submit!!");
			//       return false;
			//     }
			//   });
			// },
			// resetForm(formName) {
			//   this.$refs[formName].resetFields();
			// },
			// handleRemove(file, fileList) {
			//   console.log(file, fileList);
			// },
			// handlePreview(file) {
			//   console.log(file);
			// },
			// handleExceed(files, fileList) {
			//   this.$message.warning(
			//     `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
			//       files.length + fileList.length
			//     } 个文件`
			//   );
			// },
			// beforeRemove(file, fileList) {
			//   return this.$confirm(`确定移除 ${file.name}？`);
			// },
			// handleAvatarSuccess(res, file) {
			//   this.imageUrl = URL.createObjectURL(file.raw);
			// },
			// beforeAvatarUpload(file) {
			//   const isJPG = file.type === "image/jpeg";
			//   const isLt2M = file.size / 1024 / 1024 < 2;

			//   if (!isJPG) {
			//     this.$message.error("上传头像图片只能是 JPG 格式!");
			//   }
			//   if (!isLt2M) {
			//     this.$message.error("上传头像图片大小不能超过 2MB!");
			//   }
			//   return isJPG && isLt2M;
			// },
			// showAgreement() {
			// 	console.log("协议");
			// },
		},
	};
</script>

<style scoped lang="less">
	::v-deep .el-button--primary{
		background-color: #3370ff !important;
		border-color: #3370ff !important;
	}
	.btn-wrap {
		display: flex;
		align-items: center;
	}

	.btn-wrap-text {
		padding-left: 10px;
	}

	::v-deep .el-checkbox.is-bordered {
		width: 119px;
		height: 41px;
		background: @personInfoFormColor;
		border: none;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;

		&:hover {
			cursor: pointer;
			border: 1px solid @popupCancelTextColor;
			-webkit-box-sizing: border-box;
			box-sizing: border-box;

		}
	}

	::v-deep .el-form-item__content {
		line-height: 2.5rem;
		position: relative;
		font-size: 0.875rem;
		display: flex !important;

	}

	.tips {
		width: 300px;
		height: 20px;
		font-size: 12px;
		margin-left: 15px;
		color: gray;
	}

	.collaboration-area {
		background-color: @recommendMusicianListBGColor;
		padding: 60px;
		margin-top: 16px;
		border-radius: 6px;

		&-form {
			width: 70%;
			margin: 0 auto;
			.team-type {
					::v-deep .el-radio-group {
						display: flex;
						// background: @recommendMusicianListBGColor;
					}
				
					::v-deep .el-radio {
						width: 106px;
						height: 42px;
						background: @personInfoFormColor;
						border-radius: 4px;
						display: flex;
						align-items: center;
						justify-content: center;
						&:hover {
						  border: 1px solid @popupCancelTextColor;
						  box-sizing: border-box;
						}
					}
				
					::v-deep .el-radio__inner {
						color: #999999;
						
						// background-color: rgba(34, 36, 38, 0.0500);
					}
				
					::v-deep .el-radio__input.is-checked .el-radio__inner {
						border-color: @personInfoFormColor;
						background-color: @recommendMusicianListTextColor;
					}
				
					::v-deep .el-radio__label {
						color: @recommendMusicianListTextColor;
					}
				
					::v-deep .el-radio__inner::after {
						width: 0.25rem;
						height: 0.25rem;
						border-radius: 100%;
						background-color: @personInfoRadioBgColor;
						content: "";
						position: absolute;
						left: 50%;
						top: 50%;
					}
				
					// ::v-deep .el-radio__input .is-checked{
					// 	background-color: #222426;
					// }
				
			}
			// 上传样式
			::v-deep .avatar-uploader {
				display: flex;

				.el-upload {
					border: 1px dashed #fff;
					border-radius: 6px;
					cursor: pointer;
					position: relative;
					overflow: hidden;

					&:hover {
						border-color: #3875fb;
					}
				}
			}

			::v-deep .el-button--small {
				border: 1px solid #fff;
				color: @uploadFormTextColor;
				background-color: @uploadInputBackgroundColor  !important;

				&:hover {
					border-color: #3875fb;
					color: #3875fb;
				}
			}

			::v-deep .el-form-item__label {
				color: @uploadFormTextColor;
			}

			.avatar-uploader-icon {
				font-size: 28px;
				color: #fff;
				width: 80px;
				height: 80px;
				line-height: 80px;
				text-align: center;

				&:hover {
					color: #409eff;
				}
			}

			.avatar {
				width: 80px;
				height: 80px;
				display: block;
			}

			::v-deep .el-upload__tip {
				padding-top: 64px;
				margin-left: 8px;
				line-height: 16px;
			}

			// 修改cascader宽度
			::v-deep .el-cascader {
				width: 100%;
			}

			// 修改input背景色
			::v-deep .el-input__inner {
				height: 42px;
				background-color: @uploadInputBackgroundColor;
				border: 1px solid @uploadInputBackgroundColor;
				color: @uploadFormTextColor;

				&:hover {
					border: 1px solid #fff;
				}
			}

			::v-deep .el-form-item.is-error .el-input__inner,
			.el-form-item.is-error .el-input__inner:focus,
			.el-form-item.is-error .el-textarea__inner,
			.el-form-item.is-error .el-textarea__inner:focus,
			.el-message-box__input input.invalid,
			.el-message-box__input input.invalid:focus {
				border-color: #f56c6c;
			}

			::v-deep .el-checkbox__label {
				color: @uploadFormTextColor  !important;
			}

			::v-deep .el-tag {
				background-color: rgba(255, 255, 255, 0.08);
			}

			.submit {
				display: flex;
				align-items: center;
				/*垂直方向居中*/
				justify-content: center;
				/*水平方向居中*/
			}
		}
	}
</style>
