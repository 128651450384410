<template>
	<div>
		<PopupCommon ref="popupcommon" customClassName="textarea-modal" title=" " :visible.sync="visible"
			:width="'556px'">
			<div :slot="'title'" class="pop-title">{{$t("Collaboration.shangjiaxuzhi")}}</div>
			<div :slot="'content'" class="pop-content">
				<div>
					<ol class="xuzhi">
						<li>1.{{$t("Collaboration.xuzhi1")}}</li>
						<li>2.{{$t("Collaboration.xuzhi2")}}</li>
						<li>3.{{$t("Collaboration.xuzhi3")}}</li>
						<li>4.{{$t("Collaboration.xuzhi4")}}</li>
					</ol>
				</div>
			</div>
			<div :slot="'footer'" class="pop-footer">
				<!-- <div class="cancel" @click="cancel">{{$t('recommend.cancel')}}</div> -->
				<div class="confirm" @click="confirm">{{$t('recommend.confirm')}}</div>
			</div>
		</PopupCommon>
	</div>
</template>

<script>
	import PopupCommon from "@/components/popup/PopupCommon.vue"
	export default {
		props: {},
		components: {
			PopupCommon
		},
		data(){
			return {
				visible: false,
				// dialogVisible: false
			}
		},
		methods: {
			open() {
				this.$refs.popupcommon.open()
				// this.dialogVisible = true
				console.log('222222222')
			},
			cancel() {
				this.$refs.popupcommon.close()
			},
			confirm() {
				// this.$refs.popupcommon.close()
				this.$emit('confirm')
			},
		}
	}
</script>

<style lang="less" scoped>
	// 登录成功弹窗
	.pop-title {
		height: 68px;
		display: flex;
		align-items: center;
		border-bottom: 1px solid @demoitemtabsBGColor;
		padding: 0 25px;
		color: @recommendMusicianListTextColor;
	}
	
	.pop-content {
		max-height: 86px;
		padding: 24px;
		border-bottom: 1px solid @demoitemtabsBGColor;
		padding: 25px;
		color: @recommendMusicianListTextColor;
		line-height: 22px;
	}
	
	.pop-footer {
		display: flex;
		align-items: center;
		height: 68px;
		justify-content: flex-end;
		padding-right: 40px;
	
		// .cancel {
		// 	width: 102px;
		// 	height: 36px;
		// 	border-radius: 4px;
		// 	border: 1px solid @popupCancelBorderColor;
		// 	display: flex;
		// 	align-items: center;
		// 	justify-content: center;
		// 	font-size: 14px;
		// 	color: @popupCancelTextColor;
		// 	margin-right: 44px;
		// 	background: @popupCancelBgColor;
		// 	cursor: pointer;
		// }
	
		.confirm {
			width: 102px;
			height: 36px;
			background: #3278FF;
			border-radius: 4px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			color: #FFFFFF;
			cursor: pointer;
		}
	}
</style>